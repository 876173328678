












import Vue from 'vue'
import { Pagination } from 'vuelpers'
import { Category, Query, SortOrder } from '@/types'
import { mapActions, mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { baseCategorySelectWith } from '@/queries'

// COMPONENTS
import HomeCategories from '@/components/HomeCategories.vue'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'

// COMPONENT
export default Vue.extend({
	name: 'Categories',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Categories | ${APP_META.title}`,
		}
	},
	components: {
		HomeCategories,
	},
	created() {
		if (
			!this.$categories.data.length ||
			this.$categories.data.length !== this.$categories.total
		) {
			this.onFetchCategories({
				page: 1,
				perPage: 25,
			} as Query)
		}
	},
	computed: {
		...(mapGetters('category', ['$categories']) as Accessors<{
			$categories: Pagination<Category>
		}>),
	},
	methods: {
		...mapActions('category', ['getCategories']),
		onShowMore() {
			this.onFetchCategories({
				perPage: this.$categories.perPage,
				page: this.$categories.currentPage + 1,
			} as Query)
		},
		onFetchCategories(query: Query) {
			this.getCategories({
				...query,
				orderByColumn: 'iListOrder',
				orderByValue: SortOrder.ASC,
				with: baseCategorySelectWith,
			} as Query)
		},
	},
})
